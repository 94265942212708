import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout/layout"
import Seo from "../components/seo"

import Text from "../components/text/text"

const ConditionsPage = () => (
	<Layout>
		<div className="heading-wrap">
			<div className="heading">
				<center>
					<h1>Умови</h1>
				</center>
			</div>
		</div>

        <Text>
            <h2>Інфраструктура</h2>
            <p>
                <h3>Територія</h3>
                Власна огороджена простора територія з облаштованими ігровими зонами.<br />
                Спеціальна зона для катання на самокатах, велосипедах тощо.<br />
                Ігрові вуличні зони з накриттям, що убезпечує від дощу і спеки.<br />
                Власний фруктовий сад, ягідні кущі для вітамінізації та квіти і декоративні кущі для естетики сприйняття.
            </p>
            <p>
                <h3>Приміщення</h3>
                Кожна група простора, з якісним природнім денним освітленням та має власну туалетну кімнату.<br />
                Меблі відповідних до віку дітей розмірів.<br />
                Просторі ігрові кімнати з іграшками і матеріалами в зоні досяжності дітей.<br />
                Спеціально облаштовані куточки для Монтессорі робіт.
            </p>
            <p>
                <h3>Безпека</h3>
                Територія огороджена і підключена до пульта охоронної сигналізації.<br />
                Вхід на територію лише через підконтрольну систему домофона.<br />
                Щорічно проводяться практичні навчання з надання першої невідкладної допомоги для команди від Червоного Хреста.<br />
                Вся наша команда офіційно оформлена.
            </p>

            <h2>Денний сон</h2>

            <p>
                Ми змінили підхід до організації дитячого сну.<br />
                У нашому садочку діти сплять за бажанням.<br />
                Ми прийняли рішення відмовитись від стандартних дитячих ліжечок, натомість кожна дитина має власне спальне місце, яке відповідає всім вимогам санітарного регламенту. І по відчуттям денний відпочинок в Lady&Baby радше нагадує відпочинок у туристичному таборі на землі, аніж звична і небажана для багатьох дітей процедура денного сну.
            </p>

            <h2>Їжа та здорові звички</h2>

            <h3>Культура здорових звичок</h3>

            <p>
                В сучасному світі харчування — це вже не про потребу у виживанні, а про стиль життя, який обумовлює засадничі основи формування і підтримки доброго фізичного і психічного самопочуття.<br />
                Великою мірою діти навчаються і формують свої життєві смаки, спостерігаючи за оточенням. І наша задача урізноманітнити досвід харчування дитини, розширюючи її арсенал здорових виборів у майбутньому. І один з найкращих способів для реалізації того — це гра, ритуалізація певних аспектів поведінки, повторюваність та системність практичного досвіду.<br />
                Окрім затвердженого держспоживслужбою основного меню з 5-разовим збалансованим харчуванням без молочних каш і хліба, ми також пропонуємо нашим маленьких вихованцям кулінарні заняття “Кухар Булочка”.<br />
                В тому числі на цих заняттях діти знайомляться з авокадо, солодким перцем, зеленню та іншими свіжими овочами і фруктами. Вчаться готувати з них салати, займаються конструктором їжі, готують «здорові канапки», виготовляють випічку з не зовсім звичних інгредієнтів (безглютенові і безлактозні продукти).
            </p>

            <h2>Політика здоров’я</h2>

            <p>
                В нашому садочку ми робимо акцент на безпосередньому спілкування з природою у всіх можливих для садочку формах:
            </p>
            <p>
                Загартовування влітку:
            </p>

            <ol>
                <li>1. Босими бігати по траві корисно:</li>
                    <ul>
                        <li>для формування стопи і статури (оскільки фізіологічно стопа дитини розрахована на контакт з нерівними фактурними поверхнями, що активізує розвиток мікро м’язів стопи, що з рештою найкращим чином впливає на формування правильної фізіологічної постави дитини.</li>
                        <li>для загального-розумового розвитку (активізує сенсорні зони тактильного сприйняття, координації і підтримує в дитині бажання і вміння отримувати насолоду від контакту з природою, зокрема з природніми поверхнями). Для багатьох сучасних дітей можливість ходити босоніж є розкішшю.</li>
                        <li>для психоемоційного стану (відчуття розслаблення, злагодженості, умиротворення задоволення, свободи і легкості).</li>
                    </ul>
                <li>2. Ігри з водою.</li>
                <li>3. У дітей нашого садочку є можливість зірвати фрукти або ягоди прямо з дерева або кущика і одразу з насолодою їх з’їсти (звісно дерева і кущі не піддаються будь-якій обробці).</li>
                <li>4. Взаємодія з живою природою у вигляді равликів, яких діти доглядають: миють, годують і спілкуються.</li>
                <li>5. Догляд за кімнатними рослинами</li>
            </ol>

            <p>
                Нежить:<br />
                Не кожен нежить є обмеженням у відвідуванні дитячого закладу. Так, для дитини дошкільного віку нежить не є підставою лишатись вдома. Інакше всі садочки були б порожніми.<br />
                Нежить не завжди є ознакою хворобливого стану дитини або хвороби.<br />
                Часом, нежить є природною реакцією організму до адаптації в нових умовах, до сухого повітря, до пилу, або бути алергічною сезонною чи ситуативною реакцією.<br />
                Рідкий прозорий слиз є захисною реакцією організму, імунна система якого зараз активно вчиться боротись з вірусами і бактеріями.<br />
                Цілком природньо, що в осінньо-зимовий період організм активізує всі свої сили заради уникнення хвороби, і рідкий прозорий слиз — є одним із способів для цього.
            </p>
            <p>
                Чистота і гігієна в садочку:
            </p>
            <p>
                Наш садочок дотримується санітарних норм, передбачених державним СанПіНом України.<br />
                Санобробка, кварцування і провітрювання наших приміщень створюють комфортні та оптимальні умови для формування міцного імунітету дитини. Двічі на місяць ми провадимо генеральну дезінфекцію всього приміщення та всіх іграшок садочка.<br />
                Мийка і дезінфекція посуду провадяться виключно у посудомийній машині убезпечують нас від зайвих мікробів.<br />
                Ми використовуємо екологічні миючі засоби та сертифіковані дезинфікуючі концентрати “Полідез”.<br />
                За допомогою гідрометрів ми відстежуємо показники температури і вологості повітря.<br />
                За гігієну на кухні, якість їжі і чистоту приміщень відповідає наша медсестра з більш ніж 20-річним досвідом роботи в СЕС та наші няні-господині.<br />
                Двічі на рік вся наша команда проходить обов’язковий медичний огляд.
            </p>

            <h2>Виховання з повагою і підходи до його реалізації</h2>

            <p>
                Всупереч деяким можливим негативним уявленням про термін «виховання», ми зазначимо, що це є в нашому розумінні.
            </p>
            <p>
                Вихованням ми називаємо реалізацію природньої потреби дитини у структурі, захисті, розвиваючому і контейнеруючому просторі.
            </p>
            <p>
                Ми маємо справу з дітьми, які мислять, діють і реагують інакше, ніж ми у свій час, коли були дітьми.<br />
                Нам буває важко їх розуміти.<br />
                Підходи до виховання, які практикувались раніше, сучасним дітям відверто не пасують і ми разом з вами намагаємось вдосконалювати та поглиблювати своє розуміння сучасних дітей.<br />
                Безумовними помічниками для нас в тому є знання і дослідження про стадії розвитку дитини, про прив’язаність і про «достатньо хорошу маму».<br />
                Психоаналітичне думання скеровує нас на розуміння, логічність і причинність поведінки дитини:
            </p>

            <ul>
                <li>дитина не плаче і не кричить без причини. Просто часом є причини, які буває важко збагнути.</li>
                <li>дитина не дістає батьків своєю поганою поведінкою свідомо — вона в такий спосіб повідомляє про свої потреби, які ще не були розпізнані дорослими.</li>
                <li>дитина не є поганою, балуваною або неслухняною — вона адаптовується до умов середовища як може, використовуючи весь свій наявний дитячий ресурс.</li>
            </ul>

            <p>
                Раз за разом, тиждень за тижнем, на наших Читальнях для команди ми осмислюємо та обговорюємо праці провідних дитячих психотерапевтів та педагогів.<br />
                І ми готові ділитись нашими знаннями і надбаннями з вами на семінарах, лекціях, пікніках та Читальнях для батьків.
            </p>

            <p>
                В наших групах ви не знайдете парт.<br />
                Ми не силуємо дітей до вивчення «уроків».<br />
                «Гра є найдовершенішою формою відкриттів і досліджень»,- Альберт Ейнштейн.<br />
                Емоційна рівновага і внутрішній спокій є пріоритетною задачею для зростання дитини.<br />
                «Розвиток дитини розпочинається з точки спокою»,- Гордон Ньюфелд.
            </p>

            <h2>Навчальна програма</h2>

            <p>
                Ми пропонуємо нашим маленьким відвідувачам простір, з оптимальним поєднанням завдань для фізичного та інтелектуального розвитку у дружній доброзичливій атмосфері.<br />
                Ми віримо в дитину, в її ресурси та життєвий потенціал, і створюємо підтримуючі умови для їх реалізації.<br />
                Ми знаємо, що дитина природньо прагне до саморозвитку, і ми вибудовуємо наповнення програми згідно сензитивним періодам розвитку дитини. Ми не шукаємо способів як мотивувати дитину робити те, що “потрібно”. Ми ідемо за підказками дитини про зону її актуального і найближчого розвитку, і формуємо структуру завдань таким чином, щоб задовальняти природню дитячу спрагу в самовдосконаленні.<br />
                Методична програма, розроблена психотерапевтинею і методологинею, з урахуванням вікових особливостей розвитку дітей та унікальна тим, що складається з вивчення і дослідження елементів наук протягом навчального року (біологія, хімія, фізики, валеології, гігієни, математики, географії тощо).<br />
                Вся діяльність педагогинь і дітей наскрісно просочені підтримкою розвитку емоційного інтелекту та сенсорною стимуляцією.<br />
                Ми практикуємо інтегровані уроки, які є оптимальним поєднанням навчальних елементів з різних методологічних напрямків — Вальдорфської школи, педагогіки Марії Монтесорі, методики Зайцева, тощо.<br />
                Це є дослідження нового навчального матеріалу з області біології, хімії, фізики, валеології, гігієни, математики, літератури географії, що поєднується з музичним супроводом, артикуляційною гімнастикою, пальчиковими іграми та сучасними наочними матеріалами.
            </p>

            <h2>Ритуали</h2>

            <p>
                Для дітей є важливою прогнозованість їх садочкового дня. Бо це є однією зі складових безпеки і стабільності. Ритуали в Lady&Baby — це традиційні форми дій або активностей, які можуть часто повторюватись і є добре знайомими для дітей. Тим самим м’яко налаштовуючи їх на зміну діяльностей та проживання гарантованих приємних емоцій.
            </p>

            <h3>Про емоції і стосунки</h3>

            <p>
                Тиждень Пухнастиків<br />
                Клод Стайнер у своїй історії «Казка про Пухнастиків» розповідає про те, як слова можуть зцілювати і ранити. І слова, від яких стає тепло і затишно — це Теплі Пухнастики. А від яких недобре і зле — Холодні Колючки.
            </p>
            <p>
                Браслетики з посланнями — це різновид Теплих Пухнастиків, якими ми охоче ділимось з дітьми. А також демонструємо дітям, як генерувати таких Пухнастиків для себе самостійно, що є підґрунтям для власної спроможності в дорослому житті.
            </p>
            <p>
                Маленькі діти не зовсім ще вміють розрізняти свої почуття по відношенню до інших людей.<br />
                А також не завжди можуть розпізнати, що інші люди відчувають по відношенню до них в конкретний момент.
            </p>
            <p>
                Наші браслетики з певними посланнями, які ми обов’язково озвучуємо кожній дитині, одягаючи їх на ручку на початку Тижня Пухнастиків, є спробою називання дитині тих почуттів, які можуть відчувати до неї інші люди.
            </p>
            <p>
                І це є надзвичайно важливим, бо дає дитині досвід відчуття себе цікавою, або відчуття себе успішною, або такою, яка докладає зусиль… Бо щось таки в нашому житті часом може не виходити та продовжуючи докладати зусилля знову це «щось» все ж таки виходить. І саме тоді народжується таке відчуття — «Я знала, що ти зможеш!»
            </p>
            <p>
                І це таке гарне і круте підґрунтя на майбутнє для дорослої людини, коли з часом це відчуття спроможності стає її внутрішнім текстом. І навіть коли поруч немає такої людини, яка б могла підтримати і це сказати…то дитина, стаючи дорослою, починає саме собі це говорити: «Я знаю, що я зможу», або «Добре, що я є» чи «Я дозволяю собі це відчувати, бо всі мої почуття є прийнятними для мене»…
            </p>
            <p>
                Смайли
            </p>
            <p>
                При написанні повідомлення в будь-якому мессенджері людина може обирати супровідні смайлики для більш яскравого і влучного відображення своїх почуттів і емоцій. Такі ж смайли за бажанням дитина може обирати під час шерінгу в нашому садочку. Це дає змогу:
            </p>
            <p>
                самій дитині практикуватись у кращому розумінні і називанні своїх почуттів та встановленні причинно-наслідкових зв’язків з подіями свого життя;<br />
                дітям розвивати в собі емпатію і співпереживання до інших людей;<br />
                педагогині краще зрозуміти настрій дитини та емоційний стан групи в цілому.
            </p>

            <h3>Структура і організація побуту</h3>

            <p>
                Шерінг
            </p>
            <p>
                Під час шерінгу діти сідають в коло на підлозі, обирають смайли, діляться новинами, обговорюють актуальні теми і плани на день. Шерінг надає змогу кожній дитині зазначити своє оприсутнення в групі, поділитись важливими моментами зі свого життя, налаштуватись на взаємодію з іншими дітьми і дорослими. Шерінг є способом організації якісного стосунку в садочку. І м’якою формою впровадження, реалізації і підтримки структури і правил садочку. А також вітальною формою взаємодії при до єднанні нової дитини до групи.
            </p>
            <p>
                Дзвіночок — це звукове запрошення до зміни діяльності. Наприклад, перехід від шерінгу до заняття, або від заняття до підготовки до прогулянки.
            </p>
            <p>
                Вхід в їдальню відбувається в супроводі традиції «обери і забери»:<br />
                після відвідування вбиральні діти гуртуються біля входу в їдальню один за одним<br />
                в залежності від віку групи та бажання дитини може обиратись черговий серед дітей. В ясельних групах, зазвичай, функцію чергового виконує педагогиня.<br />
                по черзі при вході в їдальню кожна дитина обирає певну ілюстрацію, яка містить запрошення до дії. Наприклад, «дай п’ять», «обійми», «покружляй», тощо. Далі черговий надає дитині відповідь на обрану дію.<br />
                після такого привітання дитина обирає і забирає з собою щось з овочевої чи фруктової тарілки. У нас не має обов’язкової настанови з’їсти дитині те, що вона обрала. Її мінімальна задача — донести обраний продукт до столу і за бажанням з’їсти.<br />
                Таким чином ми чемно і не нав’язливої раз-за-разом запрошуємо дитину скуштувати певний овоч чи фрукт. І разом з тим це є можливість перебувати в колі однодумців, які охоче їдять овочі і фрукти.
            </p>
            <p>
                Прощання як початок
            </p>
            <p>
                Важливим вмінням зрілої особистості є здатність опрацьовувати втрати і проживати прощання, як неухильну частину життєвого досвіду.<br />
                Різкий обрив стосунку і відсутність змоги попрощатись унеможливлює для дитини створення і підтримку континуальності та безперервності буття. Іншими словами, прощання — це важливий спосіб передачі дитині відчуття того, що життя є логічним, безпечним і передбачуваним (наскільки це можливо), а важливі люди для дитини трансформуються у внутрішні хороші підтримуючі об’єкти.<br />
                Таким чином прощання з групою при переході в іншу групу, прощання з педагогинею або з садочком в цілому є важливими складовими організації садочкового життя.
            </p>

            <h3>Свята і традиції</h3>
            
            <p>
                Ми забезпечуємо умови для зростання нового покоління українок і українців. Вільних і сучасних, як і свята в Lady&Baby.<br />
                Саме тому, ми не маємо «класичних» святкових ранків з вивченням і декларуванням віршів і малоцікавими ригідними сценаріями. Ми відмовились від демонстративно-показових вистав для батьків, що здобуваються ціною нервувань їх дітей.<br />
                Свято в Lady&Baby — це час для спонтанної веселої приємної взаємодії дітей, батьків і команди.<br />
                В садочку немає святкування 8 Березня, натомість ми маємо свята Зустрічі Весни та День Мами.<br />
                Ми не відзначаємо 23 лютого, натомість ми маємо святкування Дня Козацтва.<br />
                Ми святкуємо День Святого Миколая, Великдень, Свято Весни і Свято Осені та улюблений всіма дітками костюмований Хеллоуін.
            </p>
            <p>
                Традиція святкування Дня народження
            </p>
            <p>
                Святкування Дня народження дитини з садочку розпочинається з урочистого Шерінгу. Протягом якого діти передають по колу свічку, вітаючи іменинника(-цю) і висловлюючи побажаннями на власний смак. Опісля діти групи створюють командну поробку і вручають подарунок від садочку. Часом діти мають ще й танці з мильними бульбашками. Завершення святкування відбувається солодким дієтичним частуванням від іменинника(-ці).
            </p>
            <p>
                І щоб святкування Дня народження стало ще більш веселим та яскравим ми пропонуємо батькам дитини організувати його святкування на території садочку у вихідні дні. Детальніше з умовами організації ви можете ознайомитись у адміністрації садочку.
            </p>

        </Text>
	</Layout>
)

export const Head = () => <Seo title="Умови" />

export default ConditionsPage
